import styled, { keyframes } from 'styled-components'

import { Box, Variant } from '@procsea/design-system'

const animation = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
`

export const Banner = styled(Box)<{ variant: Variant }>`
  background-color: ${props => props.theme.colors[props.variant].base};
  width: 100%;
  animation: ${animation} ${props => props.theme.transitions.duration}ms ease;
`

export const NotificationsContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10001; /* More than modal */
`

export const MenuOverlap = styled('div')`
  flex: 0 0 ${props => props.theme.menu.dimensions.desktop.width};
`
